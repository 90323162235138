import React from "react";
import InformationCard from "./InformationCard";
import {
  faHeartPulse,
  faTruckMedical,
  faTooth,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function Info() {
  return (
    <div className="info-section" id="story">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>Story</span>
        </h3>
        <p className="info-description">
          भारतीय संस्कृति में नैतिक कहानियां एक बहुत ही अहम भूमिका निभाती हैं।
          ये कहानियां हमें जीवन के ज्ञान, नैतिकता और धर्म की महत्ता सिखाती हैं।
          इन कहानियों के जरिए हमें अपने जीवन में समस्याओं का सामना करने के लिए
          सामान्य ज्ञान और ज्ञान का उपयोग करने की शिक्षा मिलती है। <br /> <br />
          “नैतिक कहानियां” की सूची में अनेक दिलचस्प कहानियाँ होती हैं, जो हमें
          जीवन के मूल्यों के बारे में सीखने में मदद करती हैं। ये कहानियाँ हमें
          जीवन के अलग-अलग पहलुओं के बारे में सिखाती हैं, जैसे कि नैतिकता,
          सहायता, सच्चाई और संयम। <br /><br />
          इन नैतिक कहानियों का संग्रह की मदद से, हम जीवन के मूल्यों को समझते हैं
          और अपने जीवन में उनका पालन करना शुरू करते हैं। इन कहानियों के माध्यम
          से, हमें समाज में एक ठोस नैतिक आधार मिलता है जो हमें सफलता और खुशहाली
          के मार्ग पर ले जाता है। <br /><br />
          ये कहानियाँ बच्चों को जीवन के मूल्यों के बारे में सिखाती हैं और उन्हें
          एक अच्छे व्यक्ति के रूप में विकसित करती हैं। इन कहानियों को सभी उम्र
          के लोगों के लिए उपयोगी माना जाता है, क्योंकि इनकी सीख हमें जीवन के
          मूल्यों को समझने में मदद करती हैं।
        </p>
      </div>
    </div>
  );
}

export default Info;
