import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../Assets/book-1.png";
import profile2 from "../Assets/book-2.png";
import profile3 from "../Assets/book-3.png";
import profile4 from "../Assets/book-4.png";
import profile5 from "../Assets/book-5.png";
import "../Styles/Doctors.css";

function BookCollections() {
  return (
    <div className="doctor-section" id="books">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Our Book Collections</span>
        </h3>
      </div>

      <div className="dt-cards-content">
        <DoctorCard img={profile1} />
        <DoctorCard img={profile2} />
        <DoctorCard img={profile3} />
        <DoctorCard img={profile4} />
        <DoctorCard img={profile5} />
      </div>
    </div>
  );
}

export default BookCollections;
