import React from "react";
import InformationCard from "./InformationCard";
import {
  faVideo,
  faChartSimple,
} from "@fortawesome/free-solid-svg-icons";
import "../Styles/Info.css";

function MoveToWards() {
  return (
    <div className="info-section" id="excellence">
      <div className="info-title-content">
        <h3 className="info-title">
          <span>Moving Towards Excellence WITH NIMBLE LEARNERS</span>
        </h3>
      </div>

      <div className="info-cards-content">
        <InformationCard
          title="Video-Based Content"
          description="Instead of learning through traditional schooling, students can easily grasp the idea with the aid of videos. Videos draw the interest of the teachers, modifying the reports of the students in turn."
          icon={faVideo}
        />

        <InformationCard
          title="Regular Tests and Practice"
          description="At the end of and chapter, practice and mock test-based learning allows students to evaluate their results. It also helps to develop students' critical thinking abilities and build up their comprehension of core concepts."
          icon={faVideo}
        />

        <InformationCard
          title="Analytics"
          description="Detailed analytics will support students in their studies to fill in the gaps."
          icon={faChartSimple}
        />
      </div>
    </div>
  );
}

export default MoveToWards;
