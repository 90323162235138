import React from "react";
import Doctor from "../Assets/bg-1.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
          Nimble Learners is a concept born out of the need for schools,
          students and parents to provide quality educational support for their
          children while keeping them safe. We offer animated content learning
          and enhanced learning from the master class that addresses student
          requirements.
          <br />
        </p>

        <h4 className="about-text-title">Your Solutions</h4>

        <SolutionStep
          title="Why Choose Us"
          description=" A Technology Learning that provides students with the
          best supplementary support for learning in key subjects for students
          of K-12 education system.
"
        />

        <SolutionStep
          title="Make a Schedule"
          description="In developing evaluations and concepts, our well-qualified and trained teachers have used AI-powered tools to track data-based progress to ensure a customized and exclusive learning experience for students."
        />
      </div>
    </div>
  );
}

export default About;
